import { useEffect, useReducer, useState } from 'react'
import { wrapArray } from './arrays'

const getStateObject = (isLoading, isError = false, isLoaded = false) => ({
  isLoading,
  isError,
  isLoaded
})
//TODO refactor to extract string cases
const loadingStateReducer = (state, {type, url}) => {
  switch (type) {
    case 'FETCH_INIT':
      return {
        ...state,
        [url]: getStateObject(true)
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        [url]: getStateObject(false, false, true)
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        [url]: getStateObject(false, true)
      };
    default:
      throw new Error('useDataApi reducer unknown action, ' + type);
  }
};

const useDataApi = (resource, context, defaultData) => {
  //HACK DT - This works in develop but during "gatsby build" contextData is undefined!
  const {contextData = {}, setContextValue} = context;
  const [urls, setUrl] = useState([]);

  useEffect(() => {
    setUrl(wrapArray(resource))
  }, [resource])

  const [loadingStates, dispatchLoadingState] = useReducer(loadingStateReducer, {});

  useEffect(() => {
    const fetchData = async (url) => {
      dispatchLoadingState({ type: 'FETCH_INIT', url });

      try {
        const mungedUrl = new URL(url, process.env.URL_PREFIX).toString().replace('https://neohed.com', 'https://www.neohed.com')
        const response = await fetch(mungedUrl);
        if (response.ok) {
          const payload = await response.json();
          dispatchLoadingState({ type: 'FETCH_SUCCESS', url });
          setContextValue(url, payload);
        } else {
          dispatchLoadingState({ type: 'FETCH_FAILURE', url });
        }
      } catch (error) {
        console.error(error);
        dispatchLoadingState({ type: 'FETCH_FAILURE', url });
      }

      return false
    };

    urls.forEach(url => {
      const loadingState = loadingStates[url];

      if (contextData[url] === undefined &&
        (
          !loadingState ||
          (!loadingState.isLoading && !loadingState.isLoaded && !loadingState.isError)
        )
      ) {
        fetchData(url);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ urls, contextData, loadingStates ]);

  return [
    (Array.isArray(resource)
      ? urls.reduce((acc, url) => {
          if (contextData[url]) {
            acc[url] = contextData[url]
          }
          return acc
        }, {})
      : contextData[resource]
    ) ?? defaultData,
    loadingStates,
    url => setUrl(wrapArray(url)),
  ];
};

export default useDataApi
