import React, { useContext, useEffect, useMemo, useState } from 'react'
import { graphql } from 'gatsby'
import lunr from 'lunr'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import LayoutSearch from '../components/LayoutSearch'
import AutoComplete from '../components/search/AutoComplete'
import useQueryParamState from '../components/search/useQueryParamState'
import useDataApi from '../lib/useDataApi'
import SetValueContext from '../context/SetValueContext'
import Radon from '../components/search/Radon'
import { isEmptyArray } from '../lib/arrays'
import { isEmptyObject } from '../lib/objects'
import PostView from '../components/PostView'
import CodeView from '../components/CodeView'
//import { css } from '@emotion/core'
//import Link from '../components/Link'
//import { bpMaxSM, bpMaxMD } from '../lib/breakpoints'

const toSearchDataUrl = (slug) => `search-data/${slug}.json`

const searchModes = {
  post: 'post',
  code: 'code'
}

function getPostSnippetsFilteredBySearch(snippetArray, codeSnippetIds, searchText) {
  return !snippetArray
    ? []
    : codeSnippetIds.reduce((mySnippets, snippetId) => {
      const { id, lang, text } = snippetArray
        .find(x => x.id === snippetId)

      let lastIndex = -1;
      const searchFilteredLines = text.toLowerCase().split(/\n/).map((line, i) => {
        if (line.indexOf(searchText) > -1) { // Filter code snippet to matching lines only.
          lastIndex = i // Used to trim blank lines from end.
          return line
        } else {
          return ''
        }
      });

      if (lastIndex > -1) {
        mySnippets.push({
          id,
          lang,
          text: searchFilteredLines.slice(0, lastIndex + 1).join('\n'),
        })
      }

      return mySnippets
    }, [])
}

// This is the "View All Articles link. KCD has this as his searchable "tiles" page
// This is the "Blog" page as outlined here: https://www.gatsbyjs.org/docs/adding-app-and-website-functionality/#how-hydration-makes-apps-possible
const Blog = ({
                data: { allMdx: { edges }, site },
              }) => {
  const context = useContext(SetValueContext)
  const [searchSuggestions] = useDataApi('search-tokens.json', context, {})
  const [searchIndexData] = useDataApi('search-index.json', context, null)
  const [searchStore] = useDataApi('search-store.json', context, null)
  const [searchIndex, setSearchIndex] = useState(null)
  const [postFilter, setPostFilter] = useState([])
  const [foundCodeSnippets, setFoundCodeSnippets] = useState({})
  const [snippetUrls, setSnippetUrls] = useState([])
  const [searchMode, setSearchMode] = useQueryParamState('mode', searchModes.post)

  useEffect(() => {
    if (searchIndexData !== null) {
      setSearchIndex(lunr.Index.load(searchIndexData))
    }
  }, [searchIndexData])

  const allBlogPosts = React.useMemo(() => {
    return edges.map(e => ({
      ...e.node.fields,
      excerpt: e.node.excerpt,
      snippets: [],
    }))
  }, [edges])
  const [searchText, setSearchText] = useQueryParamState('q')

  useEffect(() => { // 1. Process searchText changes.
    let uniqueSlugs = []
    let matchedCodeSnippets = {}
    if (searchStore !== null && searchIndex !== null && searchText !== '') {
      const refs = searchIndex.search(searchText + '*')

      if (refs) {
        const matchedPosts = refs
          .map(({ ref }) => searchStore[ref])
          .filter(post => !post.isCode)
          .map(post => post.slug)

        matchedCodeSnippets = refs.map(({ ref }) => ({
          ref,
          data: searchStore[ref],
        })).filter(({ data }) => data.isCode)
          .reduce((acc, { ref, data }) => {
            const { slug } = data
            if (!acc[slug]) {
              acc[slug] = [ref]
            } else {
              acc[slug].push(ref)
            }
            return acc
          }, {})

        uniqueSlugs = [...new Set(matchedPosts)]
      }
    }

    setPostFilter(uniqueSlugs)
    setFoundCodeSnippets(matchedCodeSnippets)
    setSnippetUrls(Object.keys(matchedCodeSnippets).map(toSearchDataUrl))
  }, [searchText, searchIndex, searchStore])

  const [codeSnippets] = useDataApi(snippetUrls, context, []) // 2. Load code data.

  const filteredPosts = useMemo(() => { // 3. Merge code data.
      return isEmptyArray(postFilter) && isEmptyObject(foundCodeSnippets)
        ? allBlogPosts
        : Object.values(Object.entries(foundCodeSnippets).reduce((postsWithCode, [slug, codeSnippetIds]) => {
          if (!postsWithCode[slug]) {
            postsWithCode[slug] = {
              ...allBlogPosts.find(post => post.slug === slug),
            }
          }
          postsWithCode[slug].snippets = getPostSnippetsFilteredBySearch(
            codeSnippets[toSearchDataUrl(slug)],
            codeSnippetIds,
            searchText
          )

          return postsWithCode
        }, allBlogPosts.filter(post => postFilter.includes(post.slug)).reduce((posts, post) => {
          posts[post.slug] = {
            ...post,
          }
          return posts
        }, {})))
    },
    [postFilter, allBlogPosts, foundCodeSnippets, codeSnippets, searchText])

  return (
    <Layout site={site}>
      <SEO/>
      <LayoutSearch
        searchHeader={
          <>
            <AutoComplete
              searchTextCallback={setSearchText}
              suggestions={searchSuggestions}
            />
            <div css={{
              display: 'flex',
              flexDirection: 'row',
              padding: '12px',
            }}>
              <Radon id="posts" name="search-mode" title="Posts" value={searchModes.post} checked={searchMode === searchModes.post}
                     onChange={setSearchMode}/>
              <Radon id="code" name="search-mode" title="Code" value={searchModes.code} checked={searchMode === searchModes.code}
                     onChange={setSearchMode}/>
            </div>
          </>
        }
      >
        {
          searchMode === searchModes.post && <PostView
            filteredPosts={filteredPosts}
            searchText={searchText}
          />
        }
        {

          searchMode === searchModes.code && <CodeView
            filteredPosts={filteredPosts}
            searchSuggestions={searchSuggestions}
            searchText={searchText}
            clickHandler={setSearchText}
          />
        }
      </LayoutSearch>
    </Layout>
  )
}

export default Blog

//TODO Check we need all these fields.
export const pageQuery = graphql`
    query {
        site {
            ...site
        }
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { published: { ne: false } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 210)
                    id
                    fields {
                        title
                        slug
                        date(formatString: "MMMM DD, YYYY")
                        banner {
                            ...bannerImage260
                        }
                    }
                    frontmatter {
                        date
                        keywords
                    }
                }
            }
        }
    }
`
