//TODO add HTML elements and props, CSS attributes, JS methods
const keywords = [
  // JavaScript
  'abstract',
  'arguments',
  'await',
  'boolean',
  'break',
  'byte',
  'case',
  'catch',
  'char',
  'class',
  'const',
  'continue',
  'debugger',
  'default',
  'delete',
  'do',
  'double',
  'else',
  'enum',
  'eval',
  'export',
  'extends',
  'false',
  'final',
  'finally',
  'float',
  'for',
  'function',
  'goto',
  'if',
  'implements',
  'import',
  'in',
  'instanceof',
  'int',
  'interface',
  'let',
  'long',
  'native',
  'new',
  'null',
  'package',
  'private',
  'protected',
  'public',
  'return',
  'short',
  'static',
  'super',
  'switch',
  'synchronized',
  'this',
  'throw',
  'throws',
  'transient',
  'true',
  'try',
  'typeof',
  'var',
  'void',
  'volatile',
  'while',
  'with',
  'yield',
  // React
  'children',
  'classname',
  'memo',
  'react',
  'render',
  'usestate',
  'useeffect',
  'useref',
  'uselayouteffect',
  'usecallback',
  'usememo',
  'usecontext',

  // HTML
  'body',
  'button',
  'canvas',
  'div',
  'document',
  'form',
  'head',
  'html',
  'image',
  'input',
  'label',
  'onchange',
  'onclick',
  'panel',
  'script',
  'span',
  'style',
  'svg',
  'table',

  // CSS
  'background',
  'backgroundcolor',
  'bold',
  'border',
  'canvasbottom',
  'color',
  'cursor',
  'fill',
  'fillstyle',
  'font',
  'hidden',
  'linewidth',
  'margin',
  'overflow',
  'padding',
  'scrollheight',
  'size',
  'stroke',
  'width',
  'height',
  'strokestyle',

  // Misc
  'array',
  'console',
  'date',
  'export',
  'filter',
  'floor',
  'foreach',
  'getownpropertynames',
  'import',
  'key',
  'map',
  'math',
  'object',
  'pow',
  'random',
  'reduce',
  'replace',
  'require',
  'round',
  'slice',
  'substr',
  'substring',
  'value',
]

module.exports = {
  keywords
}
