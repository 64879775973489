import React from 'react'
import CardTemplate from './card/CardTemplate'

const PostView = ({filteredPosts, searchText}) => {
  return (
    <>
      {
        filteredPosts.map(({ title, slug, banner, date, excerpt, snippets }, i) => <CardTemplate
          key={i}
          image={banner}
          title={title}
          url={slug}
          body={excerpt}
          snippets={snippets}
          searchText={searchText}
          styles={{ width: '320px' }}
        />)
      }
    </>
  )
}

export default PostView
