const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args))

const compose = (...fns) => (arg) =>
  fns.reduceRight((acc, fn) => (fn ? fn(acc) : acc), arg)

const debounce = (callback, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), delay)
  }
}

export {
  callAll,
  compose,
  debounce,
}
