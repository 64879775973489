import React from 'react'
import RoundButton from './RoundButton'
import './codeButton.css'

const CodeButtons = ({ tokens, clickHandler, searchText }) => {
  tokens.sort();

  return (
    <ul
      className="code-buttons"
    >
      {
        tokens.map(token => <li
          key={token}
        >
          <RoundButton
            id={token}
            text={token}
            clickHandler={clickHandler}
            searchText={searchText}
          />
        </li>)
      }
    </ul>
  )
}

export default CodeButtons
