const {keywords} = require('./keywords')

const byFrequency = ([,{count: m}], [,{count: n}]) => n - m;

const byKeyword = ([x], [y]) => {
  if (keywords.includes(y)) {
    return 1
  }
  if (keywords.includes(x)) {
    return -1
  }

  return 0
}

const byCodeAndFrequency = ([,{isCode: a, count: m}], [,{isCode: b, count: n}]) => {
  if (!a && b) {
    return 1
  }
  if (a && !b) {
    return -1
  }
  return n - m
}

function byKeyAlphabetically([a], [b]) {
  if(a < b) { return -1 }
  if(a > b) { return 1 }
  return 0
}

module.exports = {
  byCodeAndFrequency,
  byKeyAlphabetically,
  byFrequency,
  byKeyword,
}
