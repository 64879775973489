import React from 'react'
import CodeButtons from './CodeButtons'
import CardTemplate from './card/CardTemplate'
import Code from './mdx/Code'
import {byCodeAndFrequency} from '../lib/sort'
import { useTheme } from './Theming'

const CodeView = ({ searchSuggestions, filteredPosts, clickHandler, searchText }) => {
  const theme = useTheme()
  const codeWords = Object.entries(searchSuggestions).filter(([,{isCode}]) => isCode);
  codeWords.sort(byCodeAndFrequency);
  const postsWithCode = filteredPosts.filter(fp => fp.snippets.length > 0);
  //These buttons should be things people will **want** to find!

  return (
    <>
      <CodeButtons
        tokens={codeWords.slice(0, 60).map(([token]) => token)}
        clickHandler={clickHandler}
        searchText={searchText}
      />
      {
        postsWithCode.length > 0 &&
        <h3 style={{
          color: theme.colors.postLink,
          paddingBottom: '18px',
          marginTop: '8px',
        }}>Matching code extracts found!  Click for full post...</h3>
      }
      {
        postsWithCode.map(({ title, snippets, slug }, i) => <div
          key={i}
          css={{
            flex: '0 0 80%',
          }}
        >
          <CardTemplate
            title={title}
            url={slug}
            body={<>
              {
                snippets.map(({ id, lang, text }) => <Code
                  key={id}
                  className={lang}
                  codeString={text}
                  searchMode={true}
                />)
              }
            </>}
          />
        </div>)
      }
    </>
  )
}

export default CodeView
