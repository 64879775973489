import React from 'react'
import { useTheme } from './Theming'

const RoundButton = ({text, id, clickHandler, searchText}) => {
  const theme = useTheme();
  const selected = text === searchText;

  return (
    <button
      value={id}
      css={{
        border: 'none',
        background: selected ? theme.colors.title : theme.colors.bodyBg,
        color: selected ? theme.colors.bodyBg : theme.colors.primary,
        textDecoration: 'none',
        cursor: 'pointer',
        margin: '2px',
        '&:hover': {
          border: 'none',
          background: theme.colors.bodyBgAlt,
          color: theme.colors.text
        },
        '&:target': {
          backgroundColor: 'hotpink'
        }
      }}
      onClick={() => clickHandler(id)}
    >
      {
        text
      }
    </button>
  )
};

export default RoundButton
