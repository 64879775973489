import React from 'react'
import Img from 'gatsby-image'
import { useTheme } from '../Theming'
import Card from './Card'
import existential from '../../lib/existential'
import Link from '../Link'

//TODO Colors should come from theme!
const renderFooter = (footer) => {
  if (!!footer) {
    return <div style={{
      borderTop: '1px solid #ccc',
      padding: '4px 0 4px 8px',
      backgroundColor: '#efefef',
      color: '#555',
    }}>
      {
        footer
      }
    </div>
  } else {
    return null
  }
}

const CardTemplate = ({
  title,
  image,
  altText,
  body,
  footer,
  url,
  styles = {},
}) => {
  const theme = useTheme()

  return (
    <Card styles={{
      boxShadow: '0 2px 3px rgba(0,0,0,0.25)',
      backgroundColor: theme.colors.bodyBgAlt,
      margin: '0 30px 30px 0',
      ...styles
    }}
          borderRadius={6}
    >
      <Link
        style={{
          height: '100%',
          flex: '1',
          textDecoration: 'none',
          color: 'inherit',
        }}
        aria-label={`View ${title} post`}
        to={`/${url}`}
      >
        <h2 style={{
          padding: '8px',
          margin: '0',
          textAlign: 'center',
        }}>
          {
            title
          }
        </h2>
        {
          image &&
          <Img
            fluid={existential(image, 'childImageSharp.fluid')}
            alt={altText}
            style={{
              maxWidth: '100%',
            }}
          />
        }
        <div style={{
          padding: '8px 12px 12px 22px',
        }}>
          {
            body
          }
        </div>
      </Link>
      {
        renderFooter(footer)
      }
    </Card>
  )
}

export default CardTemplate
