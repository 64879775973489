function wrapArray(arg) {
  return Array.isArray(arg) ? arg : [arg]
}

function unwrapArray(arg) {
  return Array.isArray(arg) ? arg[0] : arg
}

function isEmptyArray(arr) {
  return !arr || arr.length === 0
}

export {
  wrapArray,
  unwrapArray,
  isEmptyArray,
}
