import React from 'react'

const Radon = ({ id, name, title, value, checked, onChange }) => {
  return (
    <>
      <label
        htmlFor={name}
        css={{
          fontSize: '1.1em'
        }}
      >
        {
          title
        }
      </label>
      <input
        id={id}
        name={name}
        type="radio"
        value={value}
        checked={checked}
        onChange={({target}) => onChange(target.value)}
        css={{
          border: '0px',
          width: '2em',
          height: '1.6em',
          margin: '0 1em 0 .5em',
          cursor: 'pointer',
        }}
      />
    </>
  )
}

export default Radon
