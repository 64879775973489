import React, {useRef, useState, useEffect} from 'react'
import { isEscapeKeyCode, getOffsetText, getWordBehindCursor, formatStringForSearch } from './autoCompleteUtils'
import useGetComputedFontStyles from './useGetComputedFontStyles'
import useDetermineInputWidthFromText from "./useDetermineInputWidthFromText";
import useAddEventListener from "./useAddEventListener";
import SearchIcon from "./SearchIcon";
import {byFrequency, byKeyAlphabetically} from '../../lib/sort'
import {callAll, compose, debounce} from '../../lib/functions'
import './autoComplete.css'

const MAX_RESULTS = 14;

const AutoComplete = ({
  searchTextCallback,
  suggestions
}) => {
  const autoComplete = useRef();
  const searchInput = useRef();
  const [inputText, setInputText] = useState('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [hideSuggestions, setHideSuggestions] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [offsetText, setOffsetText] = useState('');
  const [highlightIndex, setHighlightIndex] = useState(-1);

  const debouncedSearchTextCallback = debounce((text) => searchTextCallback(formatStringForSearch(text)), 800);

  useAddEventListener('keydown', (event) => {
    const keyCode = event.keyCode;
    if(isEscapeKeyCode(keyCode)) {
      setHideSuggestions(true)
    }
  });

  useEffect(() => {
    const searchTextBeforeCursor = inputText.substring(0, cursorPosition);
    setSearchWord(getWordBehindCursor(searchTextBeforeCursor));
    setOffsetText(getOffsetText(searchTextBeforeCursor));
  }, [inputText, cursorPosition])

  const [matches, setMatches] = useState([]);

  useEffect(() => {
    const sug = Object.entries(suggestions);
    if (searchWord !== '' && sug.length > 0) {
      sug.sort(byFrequency);
      const lowerSearchWord = searchWord.toLowerCase();
      setMatches(sug.filter(([name]) =>
        name === lowerSearchWord ||
        name.startsWith(lowerSearchWord)
      ).slice(0, MAX_RESULTS));
    }
  }, [searchWord, suggestions, setMatches])

  const offsetTextWidth = useDetermineInputWidthFromText(offsetText, useGetComputedFontStyles(searchInput.current));
  const displaySuggestions = !hideSuggestions && matches.length > 0;
  const updateSearchText = callAll(setInputText, compose(searchTextCallback, formatStringForSearch));

  const keyDownHandlers = {
    ArrowDown: () => setHighlightIndex((highlightIndex + 1) % matches.length),
    ArrowUp: () => {
      if (highlightIndex > -1) {
        setHighlightIndex(highlightIndex - 1)
      }
    },
    Enter: () => {
      setHideSuggestions(true);
      updateSearchText(matches[highlightIndex][0]);
    },
  };

  return (
    <div
      ref={autoComplete}
      className='autocomplete-container'
      onKeyDown={({key}) => {
        if (displaySuggestions && keyDownHandlers[key]) {
          keyDownHandlers[key]();
        }
      }}
    >
      <div className='autocomplete-input-container' style={displaySuggestions ? {
        borderBottomRightRadius: '0',
        borderBottomLeftRadius: '0',
        borderBottom: 'none',
        zIndex: '100'
      } : {}}>
        <div style={displaySuggestions ? { borderBottom: '1px solid #9AA0A6' } : {}}>
          <SearchIcon />
          <input
            className='autocomplete-input'
            type='text'
            ref={searchInput}
            value={inputText}
            onChange={({target}) => {
              const text = target.value;
              setCursorPosition(target.selectionStart);
              setHideSuggestions(false);
              updateSearchText(text);
              debouncedSearchTextCallback(text);
            }}
          />
        </div>
      </div>
      {
        !hideSuggestions &&
        <Suggestions
          matches={matches}
          searchWord={searchWord}
          offsetTextWidth={offsetTextWidth}
          highlightIndex={highlightIndex}
          setHighlightIndex={setHighlightIndex}
          updateSearchText={(name) => {
            updateSearchText(
              inputText.substring(0, inputText.lastIndexOf(searchWord))
              + name
              + ' '
            );
            setHideSuggestions(true);
            searchInput.current.focus()
          }}
        />
      }
    </div>
  )
};

const Suggestions = ({
  matches,
  searchWord,
  offsetTextWidth,
  highlightIndex,
  setHighlightIndex,
  updateSearchText,
}) => {
  matches.sort(byKeyAlphabetically)

  return (
    <div
      style={{
        visibility: matches.length > 0 ? 'visible' : 'hidden',
        zIndex: '100'
      }}
      className='autocomplete-suggestions-container'
    >
      <ul
        className='autocomplete-suggestions-list'
        style={{
          marginBlockStart: '0',
          paddingLeft: `${Math.min(260, 28 + offsetTextWidth)}px`
        }}
        onMouseOver={() => setHighlightIndex(-1)}
      >
        {
          matches.map(([name], i) =>
            <li
              key={name}
              onClick={() => updateSearchText(name)}
              style={(i === highlightIndex) ? {
                backgroundColor:  '#ddd'
              } : {}}
            >
              <Suggestion
                searchWord={searchWord}
                suggestionWord={name}
              />
            </li>
          )
        }
      </ul>
    </div>
  )
};

const Suggestion = ({searchWord, suggestionWord}) => {
  const searchWordLength = searchWord.length;

  return <>
    {
      searchWord
    }
    {
      searchWordLength < suggestionWord.length &&
      <span style={{fontWeight: 'bold'}}>
          {
            suggestionWord.substring(searchWordLength)
          }
      </span>
    }
  </>
}

export default AutoComplete;
