import React from 'react'
import { useTheme } from './Theming'

const LayoutSearch = ({searchHeader, children, ...rest}) => {
  const theme = useTheme();

  return (
    <div className="layout-search">
      <div
        style={{
          maxWidth: '500px',
          margin: 'auto',
          marginTop: '22px',
        }}
      >
        {
          searchHeader
        }
      </div>
      <div
        style={{
          width: '100%',
          maxWidth: '90vw',
          margin: '0 auto',
          padding: '40px',
          backgroundColor: theme.colors.bodyBg,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
        {...rest}
      >
        {
          children
        }
      </div>
    </div>
  )
};

export default LayoutSearch
