import React from 'react'

const Card = ({
  children,
  borderRadius = 4,
  styles = {}
}) => (
  <div
    className={'card'}
    style={{
      display: 'flex',
      flexDirection: 'column',
      borderRadius: `${borderRadius}px`,
      ...styles
    }}
  >
    {
      children
    }
  </div>
);

export default Card;
